import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["customField"]

  connect() {
    this.customFieldTarget.style.display = 'none'
  }

  hideCustom() {
    this.customFieldTarget.style.display = 'none'
    this.customFieldTarget.value = ''
  }

  showCustom() {
    this.customFieldTarget.style.display = 'block'
  }
}
