import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = ['tooltip']

  connect() {
    this.tooltipTargets.forEach(tooltip => {
      new bootstrap.Tooltip(tooltip, {html: tooltip.dataset.html == "true" || false, placement: tooltip.dataset.placement, title: tooltip.title || tooltip.dataset.title})
    })
  }
}
