import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "container", "messages", "input", "expandIcon"]

  async connect() {
    try {
      // Add escape key handler
      document.addEventListener('keydown', this.handleEscape.bind(this))

      // Add turbo stream load handler (for scrolling to bottom of chat)
      document.addEventListener('turbo:before-stream-render', this.handleTurboStreamLoad.bind(this))

      // Add turbo frame load handler (for scrolling to bottom of chat)
      document.addEventListener('turbo:frame-load', this.handleTurboFrameLoad.bind(this))

      this.loadState()
      this.setDraggingState()
    } catch (error) {
      console.error('Error initializing assistant:', error)
    }
  }

  handleTurboStreamLoad(event) {
    if (event.target.target && event.target.target.startsWith('ai_chat_')) {
      requestAnimationFrame(() => {
        this.scrollToBottom()
      })
    }
  }

  handleTurboFrameLoad(event) {
    this.scrollToBottom()
  }

  handleKeyPress(event) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      const form = event.target.closest('form')
      if (form) {
        form.requestSubmit()
        this.clearInput()
        this.scrollToBottom()
      }
    }
  }

  clearInput() {
    this.inputTarget.value = ''
  }

  submitForm(event) {
    event.preventDefault()
    const form = event.target.closest('form')
    if (form) {
      form.requestSubmit()
      this.clearInput()
    }
  }

  setDraggingState() {
    this.isDragging = false
    this.currentX = 0
    this.currentY = 0
    this.initialX = 0
    this.initialY = 0
    this.xOffset = 0
    this.yOffset = 0
  }

  loadState() {
    if (localStorage.getItem('assistantOpen') === 'true') {
      this.containerTarget.classList.add('open')

      if (localStorage.getItem('assistantExpanded') === 'true') {
        this.containerTarget.classList.add('expanded')
        this.expandIconTarget.classList.remove('mdi-arrow-expand')
        this.expandIconTarget.classList.add('mdi-arrow-collapse')
      }
    }
  }

  toggleAssistant() {
    this.containerTarget.classList.toggle("open")
    localStorage.setItem('assistantOpen', this.containerTarget.classList.contains("open"))
  }

  openAssistant(event) {
    event.preventDefault();
    if (this.containerTarget.classList.contains("open")) { return }
    this.toggleAssistant();
  }

  closeAssistant(event) {
    if (!this.containerTarget.classList.contains("open")) { return }
    this.toggleAssistant();
  }

  disconnect() {
    // Remove escape key handler
    document.removeEventListener('keydown', this.handleEscape.bind(this))

    // Remove turbo frame load handler
    document.removeEventListener("turbo:frame-load", this.handleTurboFrameLoad.bind(this))

    // Remove turbo stream load handler
    document.removeEventListener("turbo:stream-load", this.handleTurboStreamLoad.bind(this))

    // Clean up localStorage when navigating away from the site
    window.addEventListener('beforeunload', () => {
      localStorage.removeItem('assistantOpen')
      localStorage.removeItem('assistantExpanded')
      localStorage.removeItem('assistantContext')
      localStorage.removeItem('assistantConversationId')
    })
  }

  scrollToBottom() {
    if (this.hasMessagesTarget) {
      requestAnimationFrame(() => {
        this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight
      })
    }
  }

  toggleSize(event) {
    event.preventDefault()
    this.containerTarget.classList.toggle('expanded')
    localStorage.setItem('assistantExpanded', this.containerTarget.classList.contains('expanded'))

    if (this.containerTarget.classList.contains('expanded')) {
      this.expandIconTarget.classList.remove('mdi-arrow-expand')
      this.expandIconTarget.classList.add('mdi-arrow-collapse')
    } else {
      this.expandIconTarget.classList.remove('mdi-arrow-collapse')
      this.expandIconTarget.classList.add('mdi-arrow-expand')
    }
  }

  handleEscape = (event) => {
    if (event.key === 'Escape' && this.containerTarget.classList.contains('open')) {
      this.closeAssistant(event)
    }
  }

  startDragging(event) {
    if (event.target.closest('button') || event.target.closest('input')) return

    this.isDragging = true
    this.containerTarget.classList.add('dragging')

    const rect = this.containerTarget.getBoundingClientRect()
    this.initialX = event.clientX - rect.left
    this.initialY = event.clientY - rect.top

    document.addEventListener('mousemove', this.drag.bind(this))
    document.addEventListener('mouseup', this.stopDragging.bind(this))
  }

  drag(event) {
    if (!this.isDragging) return

    event.preventDefault()

    const rect = this.containerTarget.getBoundingClientRect()
    const x = event.clientX - this.initialX
    const y = event.clientY - this.initialY

    // Add 20px padding to viewport bounds
    const padding = 20
    const maxX = window.innerWidth - rect.width - padding
    const maxY = window.innerHeight - rect.height - padding

    // Keep window within viewport bounds plus padding
    this.xOffset = Math.min(Math.max(padding, x), maxX)
    this.yOffset = Math.min(Math.max(padding, y), maxY)

    // Use absolute positioning
    this.containerTarget.style.transform = 'none'
    this.containerTarget.style.top = `${this.yOffset}px`
    this.containerTarget.style.left = `${this.xOffset}px`
  }

  stopDragging() {
    this.isDragging = false
    this.containerTarget.classList.remove('dragging')
    document.removeEventListener('mousemove', this.drag.bind(this))
    document.removeEventListener('mouseup', this.stopDragging.bind(this))
  }

  setActiveButton(event) {
   let buttons = this.element.querySelectorAll('.footer-button')
   buttons.forEach(button => {
      button.classList.remove('active')
    })

    // Add active class to clicked button
    event.currentTarget.classList.add('active')
  }
}
